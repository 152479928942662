@import url('https://fonts.googleapis.com/css2?family=Tektur:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
*{
  font-family: 'Poppins', sans-serif;
                                                

}

/* src/fonts/YourCustomFont.css */

body{
  overflow-x: hidden !important; 
  background-color: black !important;
}
@font-face {
  font-family: 'MyCustomFont';
  src: url('./Fonts/Fago.ttf') format('truetype');
}

/* CSS */
.button-34 {
  background:#9f8cf7;
  border-radius: 999px;
  box-shadow: #000000 0 10px 20px -10px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 8px 40px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: fit-content;
  word-break: break-word;
  border: 0;
}
.backgroundofhero {
  background-image: url('./unc.png');
  background-size: cover; /* or background-size: contain; */
  background-position: center;
  height: 100%;
}
.bgblock {
  background-image: url('./bgblock.png');
  background-size: cover; /* or background-size: contain; */
  background-position: center;
  height: 100%;
}

.neonbg{
  background-image: url('./neon.png');
  background-size: cover; /* or background-size: contain; */
  background-position: center;
  height: 100%;
}
.bg_test{
  background-image: url('./bgtest.png');
  background-size: cover; /* or background-size: contain; */
  background-position: center;
  height: 100%;
}

.background_of_faq{
  background-image: url('./herofaq.png');
  background-size: cover; /* or background-size: contain; */
  background-position: center center;

  height: 100%;
}


.backgroundofheromobile {
  background-color: black;
  background-size: cover; /* or background-size: contain; */
  background-position: center;
  
  height: 100%;
}

.herotitle{
  color: #FFF;
  text-align: center;
  font-size: 2.25rem;
  font-family: 'MyCustomFont' !important;
  line-height: 1.25;
  font-weight: 900;
  letter-spacing: 2px;
}


@media screen and (min-width: 1000px){
  .herotitle{
    color: #FFF;
    text-align: center;
    font-family: 'MyCustomFont' !important;
    font-size: 3.75rem;
    line-height: 1;
    font-weight: 900;
  }
  
  
}





.heropara{
  color: #FFF;
  text-align: center;
  font-weight: 400 !important;
  font-size: .875rem;
  line-height: 1.25rem;

}


@media screen and (min-width: 1000px){
  .heropara{
    color: #FFF;

    text-align: center;
font-size: 1.125rem;
font-weight: 400 !important;
    line-height: 1.75rem;
  }
  
  
}







.underparahero{

  font-size: 1.125rem;
  line-height: 1.75rem;
  font-family: 'MyCustomFont' !important;
  font-weight: 700;
  text-align: center;
  color: #cdc1ff;
}
@media screen and (min-width: 1000px){
  .underparahero{
    font-size: 1.25rem;
    line-height: 1.65rem;
    font-family: 'MyCustomFont' !important;
    font-weight: 600;
    text-align: center;
    color: #cdc1ff;
  }
  
  
}


.divider{
  background-color: rgba(255, 255, 255, 0.845);
}


.head_genesis{
  font-size: 40px;
  color: #FFF;
  letter-spacing: 2px;
  text-align: center;
  font-weight: 900;
}
@media screen and (min-width: 1000px){
  .head_genesis{
    font-size: 120px;
    color: #FFF;
    text-align: start;
    font-weight: 900;
  }
  
  
}


.borderleft{
  border-left: 2px solid #9f8cf7 !important;

}


.gen_head_one{
  font-size: 20px;
  color: #9f8cf7;
}

@media screen and (min-width: 1000px){
  .gen_head_one{
      color: #9f8cf7;
      font-size: 40px;
  }
}


.gen_para_one{
  color: #FFF;
  font-size: 13px;
  font-weight: 500;
}
@media screen and (min-width: 1000px){
  .gen_para_one{
    color: #FFF;
    font-size: 17px;
    font-weight: 300;
  }
}

.borderright{
  border-right: 2px solid #9f8cf7 !important;
  border-top: 2px solid #9f8cf7 !important;
  border-radius: 0px 40px 0px 0px ;
}



.gen_head_right{
  font-size: 20px;
  color: #ffffff;
}

@media screen and (min-width: 1000px){
  .gen_head_right{
    color: #ffffff;
      font-size: 30px;
  }
}

.gen_para_right{
  color: #a08cf7c2;
  font-size: 13px;
  font-weight: 500;
}
@media screen and (min-width: 1000px){
  .gen_para_right{
    color: #9e88ffbf;
    font-size: 17px;
    font-weight: 300;
  }
}

.Accordianbg{
  background-color: #16142b !important;
  color: #FFF !important;
  border: 3px solid #a08cf792 !important;
  border-radius: 20px !important;
}

.head_of_faq{
  height: 60px !important;
  
  
}
.head_of_faq_font{
font-size: 13px !important;
font-weight: 300 !important; 
  
}
@media screen and (min-width: 1000px){
  .head_of_faq_font{
    font-size: 18px !important;
    font-weight: 300 !important;
      
    }
}


.head_of_faq_p{
  font-size: 12px !important;
  font-weight: 300 !important;
  color: #7C7CAE !important;
    
  }
  @media screen and (min-width: 1000px){
    .head_of_faq_p{
      font-size: 16px !important;
      font-weight: 300 !important;
      color: #7C7CAE !important;
        
      }
  }
  



.cardhead{
  color: #9f8cf7 !important;
    font-size: 20px !important;
}

@media screen and (min-width: 1000px){
  .cardhead{
    color: #9f8cf7 !important;
    font-size: 25px !important;
  }
  
}



.cardhead_under{
  color: #ffffff !important;
    font-size: 12px !important;
}

@media screen and (min-width: 1000px){
  .cardhead_under{
    color: #ffffff !important;
    font-size: 13px !important;
  }
  
}

.bgunique{
  background: rgb(0,0,0);
background: linear-gradient(342deg, rgba(0,0,0,1) 26%, rgba(0,0,0,1) 70%, rgba(159,140,247,1) 100%);
}

.neonText {
  color: #fff;
  text-shadow:
    0 0 7px #8400f8,
    0 0 10px #fff,
    0 0 21px #7b14d4,
    0 0 42px  #7b14d4,
    0 0 82px  #9f8cf7,
    0 0 92px  #7b14d4,
    0 0 102px #7b14d4,
    0 0 151px  #7b14d4
}



.herotitleu{
  color: #FFF;
  text-align: center;
  font-size: 27px;
  line-height: 1.45;
  font-weight: 400;
}


@media screen and (min-width: 1000px){
  .herotitleu{
    color: #FFF;
    text-align: center;
    font-size: 3.75rem;
    line-height: 1;
  
    font-weight: 400;
  }
  
  
}
.testimonial_outside{
  border: 4px solid #9f8cf7; /* Add a white border on hover */
  border-radius: 30px;
}
.borderlefts{
  border-left: 2px solid #9f8cf7 !important;
  border-top: 2px solid #9f8cf7 !important;
  border-radius: 40px 0px 0px 0px ;
}

.borderauto{
  border-right: 2px solid #8686869b !important;

  border-radius: 40px 0px 0px 0px ;
}


.blo_para_right{
  color: #abababed;
  font-size: 13px;
  font-weight: 500;
}
@media screen and (min-width: 1000px){
  .blo_para_right{
    color: #abababed;
    font-size: 17px;
    font-weight: 300;
  }
}

.gen_para_righta{
  color: #c4b7ff;
  font-size: 13px;
  font-weight: 500;
}
@media screen and (min-width: 1000px){
  .gen_para_righta{
    color: #c4b7ff;
    font-size: 17px;
    font-weight: 300;
  }
}


.gen_para_rightag{
  color: #c4b7ff;
  font-size: 13px;
  font-weight: 500;
}
@media screen and (min-width: 1000px){
  .gen_para_rightag{
    color: #c4b7ff;
    font-size: 17px;
    font-weight: 300;
  }
}

.backgroundofstep_footer{
  background-color: #000000 !important;
}

.buttoncolor_step{
  color: #9f8cf7 !important;
}

.dividern{
  background-color: #9780ff;
}

.dots{
  color: #9780ff;
  font-size: 25px;
}
.price{
  color: #9780ff;
  font-size: 11px;
}

@media screen and (min-width: 1000px){
  .price{
    color: #9780ff;
    font-size: 16px;
  }
  
}

.prices {
  color: #9780ff94;
  font-size: 11px;
  text-decoration: line-through; /* Add this line for strike-through */
}

@media screen and (min-width: 1000px) {
  .prices {
    font-size: 16px;
    text-decoration: line-through; /* Add this line for strike-through */
  }
}


.pricename{
  color: #ffffff;
  font-size: 13px;
  font-weight: 200;
}
@media screen and (min-width: 1000px) {
  .pricename{
    color: #ffffff;
    font-size: 25px;
  }
}


.steps_images{
  width: 24% !important;

  position: relative;
  bottom: 11px !important;
}
@media screen and (min-width: 1000px) {
 
.steps_images{
  width: 100px !important;
  position: relative;
  bottom: 25px !important;
}
}
.apbg {
  background-image: url('./apbgs.png');
  background-size: cover; /* or background-size: contain; */
  background-position: center;

  height: 100%;
}

.mobile_app{
  width: 100%;
}




.buttonb{
  color: rgb(162 162 199) !important;
  background-color: #56565671;
  padding: 7px;
  border-radius: 10px;
  font-size: 8px;
}

@media screen and (min-width: 1000px){
  .buttonb{
    color: #8f8f8f;
    background-color: #56565671;
    font-size: 16px;
  }
  
}

.steps_imagesb{
  width:100px;
height: 100px;
  position: relative;
  bottom: 11px !important;
}
@media screen and (min-width: 1000px) {
 
.steps_imagesb{
  width: 100px !important;
  height: 160px;
  position: relative;
  bottom: 25px !important;
}
}




.steps_imagesbb{
  width:70px;
height: 120px;
  position: relative;
  bottom: 11px !important;
}
@media screen and (min-width: 1000px) {
 
.steps_imagesbb{
  width: 90px !important;
  height: 160px;
  position: relative;
  bottom: 25px !important;
}
}



.back_of_field {
  background-color: #101010 !important; /* Adjust the color and opacity as desired */
  border-radius: 10px !important;
  color: #ffffff !important;
font-family: MyCustomFont !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2) !important; /* Adding a subtle white shadow for the glassy effect */
  -webkit-backdrop-filter: blur(30px) brightness(100%) !important;
  backdrop-filter: blur(30px) brightness(100%) !important;
 
  border: 1px solid rgba(255, 255, 255, 0.2) !important;

  

}


.centeritall{
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
}


.pricenamex{
  color: rgb(162 162 199) !important;
  font-size: 9px;
}
@media screen and (min-width: 1000px) {
  .pricenamex{
    color: rgb(162 162 199) !important;
    font-size: 15px;
  }
}



.pricenames{
  color: #ffffff;
  font-size: 10px;
  font-weight: 200;
}
@media screen and (min-width: 1000px) {
  .pricenames{
    color: #ffffff;
    font-size: 15px;
  }
}



/* CSS */
.button-34m {
  background:#9f8cf7;
  border-radius: 999px;
  box-shadow: #000000 0 10px 20px -10px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  
  font-size: 12px;
  font-weight: 700;
  line-height: 24px;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 6px 25px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: fit-content;
  word-break: break-word;
  border: 0;
}


.css-6hp17o-MuiList-root-MuiMenu-list {
  list-style: none;
margin:0;
border: 2px solid #303039;

  padding: 0;
  position: relative;
background-color: #000000 !important;
color: #7C7CAE !important;
font-weight: 900 !important;

  /* outline: 0; */
}

.gradient{
  background: -webkit-linear-gradient(#bf99f5, #9d8fe8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.proof{
  font-size: 5px !important;
}
@media screen and (min-width: 1000px) {
  .proof{
    font-size: 11px !important;
  }
}







.pricenamexs{
  color: #ffffff !important;
  font-size: 7px;
}
@media screen and (min-width: 1000px) {
  .pricenamexs{
    color: #ffffff !important;
    font-size: 14px;
  }
}





.animation_bg_unique {
  background-color: #0000009b !important;
  color: #FFF !important;
  text-align: center !important;
  margin: auto; /* Center the card horizontally */

  transition: transform 124s ease-in-out, border 123s ease-in-out;
  border: 2px solid transparent;
  max-width: 315px  !important;
}

.animation_bg_unique:hover {
  transform: scale(1.1); /* Scale up by 10% on hover */
  border: 2px solid #9f8cf7; /* Add a white border on hover */
  border-radius: 20px;
}


@media screen and (min-width: 1000px) {
  .animation_bg_unique {
    background-color: #0000009b !important;
    color: #FFF !important;
    text-align: center !important;
    margin: auto; /* Center the card horizontally */

    transition: transform 124s ease-in-out, border 123s ease-in-out;
    border: 2px solid transparent;
    max-width: 455px !important;
  }
  
  .animation_bg_unique:hover {
    transform: scale(1.1); /* Scale up by 10% on hover */
    border: 2px solid #9f8cf7; /* Add a white border on hover */
    border-radius: 20px;
  }
}




.css-6hp17o-MuiList-root-MuiMenu-list {
  list-style: none;
  margin: 0;
  padding: 0;

  position: relative;
  padding-top: 8px;
  padding-bottom: 8px;
  outline: 0;
  width: 145px !important;
}



.navbar-light .navbar-toggler-icon {
 background-image: url('./png.png') !important;
}


.text_closer{
  letter-spacing: -2px;
}