
.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;

  border: 2px solid  #9f8cf7;
  border-radius: 10px;
}
@media screen and (min-width: 1000px){
    .swiper-slide {
        background-position: center;
        background-size: cover;
        width: 800px;
        border: 2px solid  #9f8cf7;
        border-radius: 10px;
      
      }
    
    
  }
.swiper-slide img {
  display: block;
  width: 100%;
}


.swiper-button-prev:after, .swiper-button-next:after {
    font-family: swiper-icons;

    color: #9f8cf7;
    font-size: 40px;
   
}